<header mat-dialog-title>
  <h1>{{ dialogConfig?.title || defaultDialogConfig?.title }}</h1>
  <button class="button-close" mat-icon-button aria-label="close dialog" (click)="onCancelClick()">
    <fo-icon class="button-close" name="close"></fo-icon>
  </button>
</header>
<!-- Dialog Content -->
<mat-dialog-content>
  <p>{{ dialogConfig?.content || defaultDialogConfig?.content }}</p>
</mat-dialog-content>
<!-- Dialog Actions -->
<mat-dialog-actions>
  <!-- Cancel Button -->
  <button
    *ngIf="dialogConfig?.hasCloseButton"
    mat-stroked-button
    color="primary"
    (click)="onCancelClick()">
    {{ dialogConfig?.closeButtonLabel || defaultDialogConfig?.closeButtonLabel }}
  </button>
  <!-- Discard Button -->
  <button 
    *ngIf="dialogConfig?.hasDiscardButton"
    mat-stroked-button
    color="primary"
    (click)="onDiscardClick()">
    {{dialogConfig?.discardButtonLabel || defaultDialogConfig?.discardButtonLabel}}
  </button>
  <!-- Continue Button -->
  <button
    mat-raised-button
    [color]="dialogConfig?.continueButtonColor || defaultDialogConfig.continueButtonColor"
    (click)="onContinueClick()">
    {{ dialogConfig?.continueButtonLabel || defaultDialogConfig?.continueButtonLabel }}
  </button>
</mat-dialog-actions>
